@base: #009944;
@fontc:#333333;
@width:1570px;
p,ol, ul, dl{
	margin-bottom: 0;
}
.pl20{
    padding-left: 20px;
}
.warp{
    max-width: @width;
    min-width: 1200px;
    margin: 0 auto;
}
.body{
    padding-top: 100px;
}
.head{
    height: 100px;
    background-color: #071822;
    position:fixed ;
    top: 0;
    left: 0;
    z-index: 4;
    width: 100%;
    .div:extend(.warp){
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100px;
    }
    img{
       width: 151px;
       height: 37px; 
    }
    .nav-list{
        .ant-anchor{
            display: flex;
        }
        .ant-anchor-link {
            padding: 0 0 0 150px;
        }
        .ant-anchor-ink::before{
            width: 0;
        }
        .ant-anchor-wrapper{
            margin-left: 0;
            padding-left: 0;
            background-color: #071822;
        }
        .ant-anchor-link-title {
            margin-bottom: 0px;
            color: #AAAAAA;
            font-size: 24px;
        }
        .ant-anchor-link-title-active{
            color: #fff;
        }
    }
}
.banner{
    position: relative;
    width: 100%;
    .contentStyle {
        height: 840px;
        color: #fff;
        line-height: 840px;
        text-align: center;
        background: #364d79;
      }
      .banner-text{
        position: absolute;
        top: 0px;
        left: 150px;
        width: 446px;
        height: 360px;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 0px 0px 120px 0px;
        color: #828282;
        font-size: 30px;
        padding: 90px 52px 52px;
        p{
            color: @fontc;
            font-size: 48px;
            font-family: Arial;
            font-weight: 400;
            margin-bottom: 0;
        }
        p:nth-child(1){
            margin-top: 30px;
        }
      }
}
.h1568{
    height: 1568px;
}
.block{
    position: relative;
    overflow: hidden;
}
.circular{
    position: absolute;
    width: 779px;
    height: 779px;
    z-index: 2;
    transition: transform .3s linear;
}
.circular1:extend(.circular){
    top: -481px;
    left: -192px;
}
.circular2:extend(.circular){
    top: 106px;
    right: -187px;
}
.circular3:extend(.circular){
    top: 770px;
    left: -144px;
}
.circular4:extend(.circular){
    top: 2000px;
    left: -179px;
}
.circular5:extend(.circular){
    top: 2800px;
    right: -250px;
}
.circular6:extend(.circular){
    bottom: 160px;
    left: -170px;
}
.cont{
    position: absolute;
    z-index: 2;
    width: @width;
    left: 50%;
    margin-left: -785px;
}
.cont1:extend(.cont){
    top: 179px;
    display: flex;
    justify-content: space-between;
}
.cont2:extend(.cont){
    bottom: 65px;
    height: 680px;
}
.photobank{
  max-width: 910px;
  width: 57.96%;
  height: auto;  
}
.div1{
    font-size: 24px;
    color: @fontc;
    max-width: 496px;
    p:nth-child(1){
        font-size: 48px;
        margin-bottom: 111px;
        margin-top: 10px;
    }
}
.events-content{
    margin-bottom: 10px;
}
.events-content li{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.ec-img{
  width: 400px;
  border-radius: 20px 0px 0px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  .logof{
    width: 400px;
    height: 320px;
  }
}
.ec-text{
    background-color:#009944 ; 
    width: 440px;
    padding: 68px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0px 20px 20px 0px;
    p{
        color: #fff;
        font-size: 18px;
    }
    p:nth-child(1){
        font-size: 30px;
        font-family: Arial;
        font-weight: bold;

    }

} 
.h620{
    height: 660px;
}
.cont3:extend(.cont){
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    video{
        width: 60%;
        height: auto;
        display: block;
    }
    .video-dec{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: -80px;
        font-size: 26px;
        background: #009944;
        p{
            padding: 0 130px 0 60px;
            color: #fff;
        }
    }
} 
.h2166{
    height: 3360px;
}
.cont4:extend(.cont){
    top: 90px;
    .div1{
       p{
           margin-bottom: 84px;
       } 
    }
    .photobank2{
        width:97.58%;
        height: auto;
        display: block;
        margin: 0 auto;
        max-width: 1532px;
    }
    .c4text{
        border-top:1px dashed #aaa;
        font-size: 24px;
        font-family: Arial;
        font-weight: 400;
        color: @fontc;
        margin-top: 53px;
        p{
            border-bottom:1px dashed #aaa;
            line-height: 102px;
            span{
                width: 25%;
                display: inline-block;
            }
            span:nth-child(1){
                width: 14%;
            }
            span:nth-child(4){
                width: 36%;
            }
        }
    }
    .c4text1{
        font-size: 24px;
        font-family: Arial;
        font-weight: 400;
        color: @fontc;
        margin-top: 60px;
    }
}
.cont5:extend(.cont){
  top: 1280px;
  .div1 p{
      margin-bottom: 50px;
  }
  .swiper-slide{
      .swiper-hidden{
          position: absolute;
          top: 100%;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.8);
          display: flex;
          flex-direction: column;
          justify-content: center;
          transition: all 1s;
          p{
              font-size: 14px;
              color: #fff;
          }
          .zxbtn{
              width: 60%;
              background-color: @base;
              height: 40px;
              border-radius: 20px;
              margin: 0 auto;
              color: #fff;
              font-size: 16px;
              line-height: 40px;
              text-align: center;
              margin-top: 20px;
              cursor: pointer;
          }
      }
      p{
        display: block;
        text-align: center;
        font-size: 30px;
        color: @fontc;  
      }
      &:hover{
        .swiper-hidden{
            top: 0;
        }

      }
  }
  .swiper-slide-active{
      p{
          display: block;
          text-align: center;
          font-size: 30px;
          color: @fontc;
      }
  }
  #thumbs{
    //   display: none;
     .swiper-slide{
        width: 40px !important;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        img{
            width: 40px !important;
            height: 40px;
            border-radius: 50%;
            display: none;
        }
     }
  }
}
.cont6:extend(.cont){
  bottom: 100px;
  .div1 p{
    margin-bottom: 40px;
  }
  .c6text{
      font-size: 24px;
      color: @fontc;
      line-height: 36px;
      margin-right: 80px;
      span{
          color: @base;
      }
  }
  .contact{
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .clist{
          width: 100%;
          overflow: hidden;
          margin-top: 20px;
          .cl-li{
              width: 50%;
              float: left;
              display: flex;
              overflow: hidden;
              height: 100px;
              margin-top: 80px;
              div{
                  overflow: hidden;
              }
              img{
                  width: 40px;
                  height: 40px;
                  margin-right: 10px;
              }
              p:nth-child(1){
                  font-weight: bold;
                  margin-bottom: 10px;
              }
              p:nth-child(2){
                font-size: 14px;
                line-height: 20px;
            }
          }
      }
  }
}
.c7text3{
    p{
        font-size: 24px;
        margin-bottom: 6px;
        font-weight: bold;
    }
    .minip{
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 30px;
    }
    .ant-form-item{
        margin-bottom: 10px;
        font-size: 16px;
        width: 258px;
        input,textarea{
            // background-color: #091B25;
            border: 1px solid #1D2B35;
            color: #333;
        }
        .ant-input::placeholder {
            color: #515B72 !important;
          }
        button{
            background-color: @base;
            border-color: @base;
            width: 100%;
            margin-top: 6px;
            height: 40px;
            color: #fff;
        }
        button:hover{
            background-color: #097b3c;
            border-color: #097b3c;
            color: #fff;
        }
    }
    .ant-form-item-has-error{
        margin-bottom: 0;
    }
    .reach-li{
        img{
            width: 20px;
            height: 20px;
            margin-right: 6px;
        }
        p:nth-child(1){
            // font-weight: 400;
            font-size: 14px;
            line-height: 30px;
        }
        p:nth-child(2){
            font-size: 12px;
            font-weight: 400;
        }
    }
}
.h448{
    height: 408px;
    background-color: #071822;
    color: #9FA8BD;
    font-size: 12px;
    // padding-top: 40px;
}

.cont7:extend(.warp){
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 338px;
    .c7text1{
        max-width: 868px;
        img{
            width: 151px;
            height: 37px;
        }
        p{
            line-height: 20px;
            margin-top: 30px;
            en{
                font-weight: bold;
                font-size: 18px;
            }
        }
    }
    .c7text2{
        font-size: 16px;
        white-space: nowrap;
        margin: 0 90px 0 20px;
        p:nth-child(2){
            font-size: 36px;
           font-weight: 300;
           font-family: Source Han Sans CN;
        }
    }
    .c7text3{
        max-width: 450px;
        margin-left: 40px;
    }
    
}
.cont8:extend(.warp){
    height: 70px;
    border-top: 1px solid #586270;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
        width: 20px;
        height: 20px;
        margin-right: 6px;
    }
    a{
        color:#9FA8BD;
        display:flex;
        align-items: center;
    }
}
.swiper-wrapper{
    margin-left: 1%;
    overflow: hidden;
    width: 100%;
    .swiper-slide{
        position: relative;
        width: 24%;
        float: left;
        margin-right: 1%;
        margin-bottom: 4%;
        overflow: hidden;
        img{
            width: 100%;
        }
    }
}
@media screen and (max-width: 1570px) {
    .warp{
        width: 100%;
        margin: 0 auto;
        padding: 0 24px;
    }
    .cont{
        width: 100%;
        margin-left: -50%;
        padding: 0 24px;
    }
    .cont4 .c4text p {
        line-height: 50px;
        span{
            padding: 0 4px;
        }
    }
    #gallery {
        width: 100%;
        max-width: 1570px;
        min-width: 900px;
    }
    .swiper-container-3d {
        perspective: calc(100%-200px);
    }
    .cont5 .div1 p {
        margin-bottom: 100px;
    }
}
.ant-carousel .slick-dots li{
    width: 24px;
    height: 24px;
    button{
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #ffff00;
        border: 1px solid #000;
    }
} 
.ant-carousel .slick-dots li.slick-active button {
    background: #ffff00;
}
.link-text{
    line-height: 27px;
    padding-left: 50px !important;
    a{
        font-size: 16px !important;
        border-bottom: 1px solid #AAAAAA;
    }

}